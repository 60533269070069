.page-header {
    background:  url("./bg.png"), $pageHeaderBackground;
    //background: url("/images/bg-areavibes.png"), $primary-color; 

    background-size: 100% auto;
    background-position: bottom;

    min-height: 400px;
    padding: 5rem 0;
    
    display: flex;
    align-items: center;

    & * {
        color: $ll-light-color;
    }
}