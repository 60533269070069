.button-toolbar-parent {
    position: relative;

    & .button-toolbar{
        position: absolute;
        right:0;
        opacity: .2;

        &:hover {
            opacity: 1 !important;
        }
    }

    &:hover .button-toolbar {
        opacity: .6;
    }

    
}
