.page-options {
    & > div {
        background: $secondary-color;
        padding: .25rem;
        text-align:center;
        border-top: 1px solid $d-secondary-color;
        width:100%;
        z-index: 101;
        a, .format-link {
            background: $secondary-color;
            margin: 0 10px;
            padding: .125rem 3rem;
            color: $l-secondary-color;
            font-size: 100%;

            &:hover {
                background: $secondary-color;
                color: $ll-light-color;

            }
    
        }
    }

}