.asteroid-page-section {
    border-radius: $borderRadius;
    border-radius: 4px;
    padding: 5rem 0;

    h2 {
        color: $dark-color;
        margin: 1rem auto;
        padding: 1rem;
        width: fit-content;
        border-bottom: $linkBorderSize solid $primary-color;
        
    }


}

//The odd
.page-container > .view-item > .asteroid-page-section:nth-of-type(2n) {
    background: $ll-light-color;
}

//The even
.page-container > .view-item > .asteroid-page-section:nth-of-type(2n+1) {
    background: $ll-secondary-color;
}


.content-box {
    background: $ll-light-color;
    padding:2rem;
    border-radius: $borderRadius;
}

.item-link {
    background-color: $l-light-color !important;
    color: $d-dark-color !important;
    border: $linkBorderSize solid transparent !important;
    text-decoration: none !important;

    &:hover {
        background-color: $ll-light-color !important;
        color: $d-dark-color !important;
        text-decoration: none !important;
        border: $linkBorderSize solid $secondary-color !important;
    }
}
