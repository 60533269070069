
/* ------------------------*/
a:not(.simple-link),
.format-link, 
button {
    cursor: pointer;
    display: inline-block;

    color: $linkColor;
    background: $linkBackground;
    border: $linkBorderSize solid transparent;
    border-radius: $borderRadius;
    
    text-decoration: none;
    font-weight: 900;
    font-size: 100%;

    padding: 0.5rem 3rem;
    margin-bottom: 10px;
    
    &:hover {
        border: $linkBorderSize solid $linkBackground;
        background: $linkColor;
        color: $linkBackground;
    }

    &.small-link {
        padding: .5rem;
        background: transparent;
        color: $linkBackground;

        &:hover {
            color: $linkHoverColor;        
            background: $linkHoverBackground;
        }
    }
}

.simple-link {
    cursor: pointer;
    color: $linkBackground;

    &:hover {
        text-decoration: underline;
    }
}

.inline-edit-button {
    position: absolute;
    right: -10px;
    top: 0;
}


a > .link-reg-text {
    display:inline;
}

a:hover > .link-reg-text {
    display:none;
}


a > .link-hover-text {
    display:none;
}

a:hover > .link-hover-text {
    display:inline;
}