#header {
    position: fixed;
    width: 100%;
    z-index: 102;
    box-shadow: 0px 0 8px $menuShadowColor;
    background: $headerMenuBackgroundColor;
    top: 0;

    h3 {
        margin: 0px;
        font-family: $topFont;
        color: $headerTextColor;
    }

    #main-logo-link {
        background: none;
        display: block;
        width: fit-content;
        padding: 0;
        margin: 0 5rem 0 0;

        &:hover {
            border-color: transparent;
        }

        img#main-logo {
            display: block;
            height: 36px;
        }
    }
}



.header-top-line, .header-main-line {
    display: flex;
    align-items: center;

    & > nav {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        & > a {
            margin: 0;
            padding: 0;
        }
    }

}


.header-top-line {
    color: $light-color;
    padding: 0.25rem 2rem;
    border-bottom: 1px solid rgba($grey-color, 0.5);

    .hmenu-item {
        padding: 0.125rem 0.5rem !important;
        font-size: 14px !important;

        & a,
        & * {
            color: $l-grey-color !important;
        }
    }
}

.header-main-line {
    border-bottom: 1px solid $l-light-color;
    padding: 0.5rem 2rem;
}





.hmenu-dropdown {
    position: relative;
}
.hmenu-dropdown-options {
    position: absolute;
    padding: 0.25rem;
    width: 100%;
    top: 100%;
    min-width: max-content;
    background: $headerMenuBackgroundColor;
    z-index: 105;

    //Hover items in a dropdown
    .hmenu-item:hover {
        background-color: $menuLinkHoverColor !important;
        & a,
        & * {
            color: $menuLinkHoverBackgroundColor !important;
        }
    }
}



.hmenu-dropdown-title,
.hmenu-item {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap;
}



.hmenu-item {
    cursor: pointer;

    color: $menuLinkColor !important;
    background: none !important;
    border: none !important;

    text-decoration: none;
    font-weight: 500 !important;
    font-size: 18px !important;
    font-family: $headerFont;
    text-transform: uppercase;

    padding: 0.5rem !important;

    & a,
    & * {
        color: $menuLinkColor !important;
    }

    &:hover {
        & a,
        & * {
            color: $menuLinkHoverColor !important;
        }
    }

    .fas {
        font-size: 75%;
        margin-right: 4px;
    }
}

#user-profile-select {
    width: 300px;
    cursor: pointer;
}