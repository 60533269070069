.asteroid-object-field {
    border: 1px solid $light-color;
    margin-bottom: 10px;

    & > div {
        display: flex;
        & > div {
            width: 50%;
            padding:.25rem;
            &:first-child {
                border-right: 1px solid $light-color;
            }

        }
        &:nth-child(2n) {
            background-color: $l-light-color;
        }
        
    }
}