.ll-primary-color { color: $ll-primary-color !important; }
.l-primary-color { color: $l-primary-color !important; }
.primary-color { color: $primary-color !important; }
.d-primary-color { color: $d-primary-color !important; }
.dd-primary-color { color: $dd-primary-color !important; }

.ll-secondary-color { color: $ll-secondary-color !important; }
.l-secondary-color { color: $l-secondary-color !important; }
.secondary-color { color: $secondary-color !important; }
.d-secondary-color { color: $d-secondary-color !important; }
.dd-secondary-color { color: $dd-secondary-color !important; }

.ll-highlight-color { color: $ll-highlight-color !important; }
.l-highlight-color { color: $l-highlight-color !important; }
.highlight-color { color: $highlight-color !important; }
.d-highlight-color { color: $d-highlight-color !important; }
.dd-highlight-color { color: $dd-highlight-color !important; }

.ll-light-color { color: $ll-light-color !important; }
.l-light-color { color: $l-light-color !important; }
.light-color { color: $light-color !important; }

.l-grey-color { color: $l-grey-color !important; }
.grey-color { color: $grey-color !important; }
.d-grey-color { color: $d-grey-color !important; }

.dark-color { color: $dark-color !important; }
.d-dark-color { color: $d-dark-color !important; }
.dd-dark-color { color: $dd-dark-color !important; }



.error-color { color: $error-color !important; }
.success-color { color: $success-color !important; }
.warning-color { color: $warning-color !important; }
.loading-color { color: $loading-color !important; }


.ll-primary-bg { background-color: $ll-primary-color !important; }
.l-primary-bg { background-color: $l-primary-color !important; }
.primary-bg { background-color: $primary-color !important; }
.d-primary-bg { background-color: $d-primary-color !important; }
.dd-primary-bg { background-color: $dd-primary-color !important; }

.ll-secondary-bg { background-color: $ll-secondary-color !important; }
.l-secondary-bg { background-color: $l-secondary-color !important; }
.secondary-bg { background-color: $secondary-color !important; }
.d-secondary-bg { background-color: $d-secondary-color !important; }
.dd-secondary-bg { background-color: $dd-secondary-color !important; }

.ll-highlight-bg { background-color: $ll-highlight-color !important; }
.l-highlight-bg { background-color: $l-highlight-color !important; }
.highlight-bg { background-color: $highlight-color !important; }
.d-highlight-bg { background-color: $d-highlight-color !important; }
.dd-highlight-bg { background-color: $dd-highlight-color !important; }

.ll-light-bg { background-color: $ll-light-color !important; }
.l-light-bg { background-color: $l-light-color !important; }
.light-bg { background-color: $light-color !important; }

.l-grey-bg { background-color: $l-grey-color !important; }
.grey-bg { background-color: $grey-color !important; }
.d-grey-bg { background-color: $d-grey-color !important; }

.dark-bg { background-color: $dark-color !important; }
.d-dark-bg { background-color: $d-dark-color !important; }
.dd-dark-bg { background-color: $dd-dark-color !important; }


.error-color-bg { background-color: $error-color !important; }
.success-color-bg { background-color: $success-color !important; }
.warning-color-bg { background-color: $warning-color !important; }
.loading-color-bg { background-color: $loading-color !important; }