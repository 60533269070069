/* THEME COLORS */
$mainBgColor: $light-color; //

//HEADER & MENUS
$headerMenuBackgroundColor: $primary-color; //
$headerTextColor: $ll-light-color; //

$menuShadowColor:  $light-color; //

$menuLinkColor: $ll-light-color;
$menuLinkBackgroundColor: $ll-light-color; //
$menuLinkHoverColor: $secondary-color; //
$menuLinkHoverBackgroundColor: $ll-light-color; //

//FOOTER
$footerBackgroundColor: $primary-color; //
$footerTextColor: $l-primary-color; //

$footerAccentColor: $dark-color; //

//DEFAULT TEXT
$textFontColor: $dark-color; //
$headerFontColor: $d-primary-color; //
$topFontColor: $dd-dark-color; //

//LINKS
$linkColor: $ll-light-color; //
$linkBackground: $secondary-color; // 
$linkHoverColor: $ll-light-color; //  
$linkHoverBackground: $highlight-color; //


$borderRadius: 4px;
$linkBorderSize: 2px;




$pageHeaderBackground: $secondary-color;