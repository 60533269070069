.loader-summary-bar {
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    align-items: center;
    background: rgba(255, 255, 255, 0.25);
    padding: 0 1rem;
    border-radius: $borderRadius;
    margin-bottom: 10px;
}

.asteroid-search-bar {
    flex-grow: 4;
    
    //margin-right: 60px;
    width:100%;

    input {
        width: 100%;
    }
}

.asteroid-filter-box,
.asteroid-sort-box {
    //margin-right: 40px;
    width:100%;
    flex-shrink: 4;
}

.asteroid-toolbar-options {
    background: rgba(255, 255, 255, 0.25);
    padding: 10px 10px 0px;
    border-radius: $borderRadius;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
