$textFont: 'Open Sans', serif;
$headerFont: 'Lato';
$topFont: 'Lato', sans-serif;



p, a, li {
	font-size: 1em;
	line-height:150%;
	font-family: $textFont;
	color: $textFontColor;
}

@mixin h-tag($color: $headerFontColor, $font: $headerFont) {
	font-family: $font;
	color: $color;
	margin: 5px 0;
}

h1 {
	@include h-tag($font: $topFont, $color: $topFontColor);
	font-weight: bolder;
	font-size: 5em;	
    text-transform: uppercase;
}

h2 {
	@include h-tag;
	font-size: 2.3em;
    text-transform: uppercase;
}
h3 {
	@include h-tag;
	font-size: 1.8em;
    text-transform: uppercase;
}
h4 {
	@include h-tag($font: $textFont);
	font-size: 1.6em;
}
h5 {
	@include h-tag($font: $textFont);
	font-size: 1.3em;
	font-weight:bold;
}
