#hero {
    //background: linear-gradient(rgba($l-secondary-color, .3), rgba($l-secondary-color, .65)),  url("https://www.areavibes.com/images/home-image.jpg");
    //background: linear-gradient(rgba($l-secondary-color, .3), rgba($l-secondary-color, .65)),  url("https://www.areavibes.com/images/home-image.jpg");
    background: linear-gradient(rgba($l-secondary-color, .3), rgba($l-secondary-color, .65)),  url("https://assets.website-files.com/610978b044b6ab813c0174d7/61153390fe8d4de4a399e037_white-background-red-poppy-architecture.jpg");
    
    
    background-size: cover;
    background-color: black;
    background-repeat: no-repeat;
}

#hero .container {
    min-height: 250px; 
    height: 100vh;
    display: flex;
    align-items: center;
}

#hero h1, #hero h3 {
    color: black !important;
    text-shadow: none;
}

#hero h1 {
    margin-bottom:25px;
}

#hero h3 {
    margin-bottom: 25px;
}

#hero p {
    margin-top: 10px;
}

#hero h4, #hero p {
    color: rgba(0,0,0,.5);
}

.section-container {
    padding: 10rem 1rem;
    text-align: left;
}

//The odd
#page > div.section-container:nth-of-type(2n) {
    background: $ll-light-color;
    h4 {
        color: $secondary-color;
        font-weight: 900;
    }
}

//The even
#page > div.section-container:nth-of-type(2n+1) {
    background: $l-light-color;
    h4 {
        color: $secondary-color;
        font-weight: 900;
    }
}


//The first
#page > div.section-container:nth-of-type(3n + 2) {
    background: $d-secondary-color;
    h4 {
        color: $ll-light-color;
        font-weight: 900;
    }
}


.section-container .row {
    text-align:center;
}

.how-does-it-work-steps p {
    margin: 0; 
}
.how-does-it-work-steps h3 {
    margin: 0;
}

.how-does-it-work-steps > div {
    height:100%;
    padding:1rem;
}

.how-does-it-work-steps > div > div {
    background-color: white;
    border-radius: $borderRadius;
    box-shadow: 1px 4px 4px 0px #ccc;
}

.hiws-title {
    padding:1rem;
    background-color:$d-secondary-color;
    position:relative;
    border-top-right-radius:4px;
    border-top-left-radius:4px;

    p, h1, h3 {
        color: white !important;
    }

    h1 {
        position:absolute;
        left:-12px;
        top:-12px;
        font-size: 48px;
        width:72px;
        height:72px;
        display:flex;
        justify-content: center;
        align-items: center;
        background: $dd-secondary-color;
        border-radius:100%;
    }
}

.hiws-body {
    padding:0 1rem 1rem;
    position:relative;
}


.icon-1 {
    width:144px;
}

.icon-2 {
    width:144px;
}





.color-test-square {
    display:inline-block;
    width:50px;
    height:50px;
    border: 4px inset #fff;
}