label, input, select, textarea {
    font-size: 1em !important;
}

label {
    margin-bottom: 0;
}

.form-control {
    height: auto !important;
}

select {
    cursor:pointer;
}

input,
input.form-control,
textarea.form-control {
    border-radius: $borderRadius;
    background-color: $l-light-color;
    border: none;
    color: $d-grey-color !important;
    padding: .5rem 1rem;

    box-shadow:none;
    
    border: $linkBorderSize solid $secondary-color;
    text-decoration: none;
    font-weight: 500;
    font-size: 100% !important;

    &:focus, &:active {
        background-color: $ll-light-color !important;
        color:  $dd-dark-color !important;
        border-color: $d-secondary-color !important;
        box-shadow:none;
    }
}

select {
    border-radius: $borderRadius;
    background-color: $secondary-color;
    border: none;
    color: $ll-light-color;
    padding: .5rem 1rem;
    
    border: $linkBorderSize solid transparent;
    text-decoration: none;
    font-weight: 900;
    font-size: 100% !important;

    &:hover {
        background-color: $ll-light-color;
        color:  $secondary-color;
        border-color: $secondary-color;
    }
}

::placeholder { 
    color: $grey-color !important;
    opacity: 1; 
    font-weight:400;
  }

input[type=checkbox]{
    width: auto;
    display: inline-block;
    margin-left: 8px;
    transform: scale(1.3);
    cursor: pointer;
}

.form-group {margin-bottom:25px !important;text-align:left;}
