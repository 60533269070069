.paginationLinks {
    .format-link {
        margin: .5rem;
        padding: .5rem;
        background:white;
        color: #14A9EE;
    }

    .active-page {
        background:#14A9EE;
        color: white;
    }
}