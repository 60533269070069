.modal-far-bg {
    position:fixed;
    width:100vw;
    height:100vh;
    //TODOFIX get color vars in here
    background:rgba(white, .6);
    z-index: 1000;
    top:0;
    left:0;
}

.modal-body {
    //TODOFIX get color vars in here
    background:white;
    border-radius: $borderRadius;
    overflow-y: scroll;
    max-height:80vh;

    margin: 50px auto;
    z-index: 1001;
    
    box-shadow: 1px 1px 6px 0px $grey-color;
}

.modal-close {
    display: inline-block;
    position: absolute;
    right: 2rem;
    cursor: pointer;
    z-index: 100;
}

