

.tab-toggle-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: -1rem;
    padding: 1rem;
    text-align:right;

    &:hover {
        color: $l-primary-color;
        background: $ll-secondary-color;

        & .tab-toggle {
            background-color: $ll-primary-color;
        }
    }


    .tab-toggle {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size:90%;
        color: $primary-color;
        
    }
}