
$primary-color: #2D80B1;
$secondary-color: #CCE4ED;
$highlight-color: #61A02A;


$hContrast: 55%;
$bContrast: 35%;
$sContrast: 20%;

$ll-primary-color: lighten($primary-color, $bContrast);
$l-primary-color: lighten($primary-color, $sContrast);
$d-primary-color: darken($primary-color, $sContrast);
$dd-primary-color: darken($primary-color, $bContrast);

$ll-secondary-color: lighten($secondary-color, $bContrast);
$l-secondary-color: lighten($secondary-color, $sContrast);
$d-secondary-color: darken($secondary-color, $sContrast);
$dd-secondary-color: darken($secondary-color, $bContrast);

$ll-highlight-color: lighten($highlight-color, $bContrast);
$l-highlight-color: lighten($highlight-color, $sContrast);
$d-highlight-color: darken($highlight-color, $sContrast);
$dd-highlight-color: darken($highlight-color, $bContrast);

$ll-light-color: desaturate( lighten($l-primary-color, $hContrast), $hContrast);
$l-light-color: desaturate( lighten($l-primary-color, $bContrast), $hContrast);
$light-color: desaturate( lighten($l-primary-color, $sContrast), $hContrast);

$dd-dark-color: desaturate( darken($d-primary-color, $hContrast), $hContrast);
$d-dark-color: desaturate( darken($d-primary-color, $bContrast), $hContrast);
$dark-color: desaturate( darken($d-primary-color, $sContrast), $hContrast);

$l-grey-color: darken($l-light-color, $bContrast);
$grey-color: darken($l-light-color, $hContrast);
$d-grey-color: lighten($d-dark-color, $bContrast);


$error-color: #f00;
$success-color: #0f0;
$warning-color: #ff0;
$loading-color: #ccc;





:root {
    --ll-primary-color: #{$ll-primary-color};
    --l-primary-color:  #{$l-primary-color};
    --primary-color:  #{$primary-color};
    --d-primary-color:  #{$d-primary-color};
    --dd-primary-color:  #{$dd-secondary-color};

    --ll-secondary-color: #{$ll-secondary-color};
    --l-secondary-color:  #{$l-secondary-color};
    --secondary-color:  #{$secondary-color};
    --d-secondary-color:  #{$d-secondary-color};
    --dd-secondary-color:  #{$dd-secondary-color};

    --ll-highlight-color: #{$ll-highlight-color};
    --l-highlight-color:  #{$l-highlight-color};
    --highlight-color:  #{$highlight-color};
    --d-highlight-color:  #{$d-highlight-color};
    --dd-highlight-color:  #{$dd-highlight-color};
    
    --ll-light-color: #{$ll-light-color};
    --l-light-color:  #{$l-light-color};
    --light-color:  #{$light-color};
    
    --l-grey-color:  #{$l-grey-color};
    --grey-color:  #{$grey-color};
    --d-grey-color:  #{$d-grey-color};
    
    --dark-color:  #{$dark-color};
    --d-dark-color:  #{$d-dark-color};
    --dd-dark-color:  #{$dd-dark-color};

    --error-color: #{$error-color};
    --success-color: #{$success-color};
    --warning-color: #{$warning-color};
    --loading-color: #{$loading-color};
}

