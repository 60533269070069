* {
    box-sizing: border-box;
}
.body,
.controller,
.page-container {
    min-height: 70vh;
}

#root {
    margin-top: 94px;
}

.main-screen-persist-menu {
    width: 75vw;
    margin-left: 25vw;
}

.body {
    background-color: $mainBgColor;
    background: linear-gradient(rgba($mainBgColor, 0.5), rgba($mainBgColor, 0.5)), url($backgroundImage);
    background-size: 100% auto;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.main-page-container > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: unset;
    min-height: 70vh;
    align-content: space-between;
    & > div {
        width:100%;
    }
}
